import type { SelectItem } from '@emotioncod/cm-design-system';
import type {
  LocationOpenStreetMap,
  JobLocationValues
} from '~/types/bo-types';

export function resolveLocationsItemSelect(
  locations: LocationOpenStreetMap[]
): SelectItem[] {
  return locations.map((location) => ({
    text:
      (location.address.city ||
        location.address.town ||
        location.address.municipality ||
        location.address.village) +
      ' (' +
      location.address.country +
      ')',
    value: {
      place_id: location.place_id,
      name:
        location.address.city ||
        location.address.town ||
        location.address.municipality ||
        location.address.village,
      country: location.address.country,
      lat: location.lat,
      lon: location.lon
    }
  }));
}

/**
 * Normalize locations values to string[]
 * TODO in the future we should normalize to JobLocationValues[]
 * @param values
 */
export function resolveLocationsValues(
  values: Array<string | JobLocationValues>
) {
  return values.map((value) => {
    if (typeof value === 'string') {
      return { text: value, value };
    }

    // return value;
    return { text: value.name, value: value.name };
  });
}

/**
 * Normalize locations values to string[]
 * TODO in the future we should normalize to JobLocationValues[]
 * @param values
 */
export function normalizeLocationsValues(
  values: Array<string | JobLocationValues>
): string[] {
  return values.map((value) => {
    if (!value) return '';

    if (typeof value === 'string') {
      // return { name: value, country: '', lat: '', lon: '', place_id: 0 };
      return value;
    }

    if (
      typeof value === 'object' &&
      'text' in value &&
      typeof value.text === 'string'
    ) {
      // return { name: value.text, country: '', lat: '', lon: '', place_id: 0 };
      return value.text;
    }

    // return value;
    return value.name;
  });
}

export function resolveLocationsObject(
  locations: Array<string | JobLocationValues>
): string {
  return locations
    .map((location) => {
      if (typeof location === 'string') {
        return location;
      }
      return location.name;
    })
    .join(', ');
}
