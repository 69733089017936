import { ComputedRef } from 'vue/dist/vue';
import {
  EventType,
  Status
} from '@emotioncod/cm-definitions/lib/Matches/const';
import type { Match } from '@emotioncod/cm-definitions/lib/Matches';
import { resolveCompanyNameFromId } from '~/resolvers/companies';
import { formatDate } from '~/utils';
import type { ClientPagination, SortableMatchArr } from '~/types/bo-types';
import { resolvePositionType } from '~/resolvers/jobPositions';
import { VALID_STATUS_MATCH } from '~/config/validStatusMatch';

export const useMatchesGetters = defineStore('matches.getters', () => {
  const matchesStore = useMatches();
  const jobPositionsStore = useJobsPositions();
  const companiesStore = useCompanies();

  async function remappingMatchForTableFields(
    match: Match
  ): Promise<SortableMatchArr> {
    const companyName = await resolveCompanyNameFromId(
      match.jobPosition?.company_id || match.jobPosition?.company?.id || ''
    );

    const positionType = match.jobPosition?.source
      ? resolvePositionType(match.jobPosition?.source)
      : '-';

    // eslint-disable-next-line camelcase
    const created_at = match?.date
      ? formatDate(match.date)
      : formatDate(match.created_at);

    return {
      ...match,
      // eslint-disable-next-line camelcase
      created_at,
      companyName,
      positionType
    };
  }

  async function getMatchesForSortTable(): Promise<SortableMatchArr[]> {
    return await Promise.all(
      matchesStore.matches.map(remappingMatchForTableFields)
    );
  }

  async function getMatchInShortlist(): Promise<
    (Match & { companyName: string; positionType: string })[]
  > {
    const resultFiltered = matchesStore.matches.filter(
      (item: Match) =>
        item.status.toLowerCase() === Status.MATCH.toLowerCase() &&
        item.shortlistStatus?.name.toLowerCase() !==
          EventType.DELISTED.toLowerCase()
    );

    return await Promise.all(resultFiltered.map(remappingMatchForTableFields));
  }

  async function getMatchInOpportunityStatus() {
    const resultFiltered: Match[] = matchesStore.matches?.filter(
      (item: Match) =>
        item.status.toLowerCase() === Status.OPPORTUNITY.toLowerCase()
    );

    return await Promise.all(resultFiltered.map(remappingMatchForTableFields));
  }

  async function getMatchOpenSelectionStatus() {
    const resultFiltered: Match[] = matchesStore.matches?.filter(
      (item: Match) =>
        VALID_STATUS_MATCH.openSelections.includes(item.status.toLowerCase())
    );

    return await Promise.all(resultFiltered.map(remappingMatchForTableFields));
  }

  async function getMatchHistoryStatus() {
    const resultFiltered: Match[] = matchesStore.matches?.filter(
      (item: Match) =>
        VALID_STATUS_MATCH.history.includes(item.status.toLowerCase()) ||
        item.shortlistStatus?.name.toLowerCase() ===
          EventType.DELISTED.toLowerCase()
    );

    return await Promise.all(resultFiltered.map(remappingMatchForTableFields));
  }

  async function getMatchesForCompanies(
    filterBy:
      | 'company-details'
      | 'company-users'
      | 'company-jobPositions'
      | 'company-matches'
      | 'company-applications'
      | 'company-openSelections'
      | 'company-history',
    search?: string
  ) {
    switch (filterBy) {
      case 'company-details':
        return;
      case 'company-users':
        return companiesStore.getCompanyUsers(search);
      case 'company-jobPositions':
        return jobPositionsStore.getterJobsPositions(search);
      case 'company-matches':
        return await getCompanyMatches(search);
      case 'company-applications':
        return await getCompanyApplications(search);
      case 'company-openSelections':
        return getCompanyOpenSelections(search);
      case 'company-history':
        return await getCompanyHistory(search);
      default:
    }
  }

  async function getCompanyMatches(search?: string) {
    const resultFiltered: Match[] = matchesStore.matches
      .filter(
        (item: Match) =>
          item.status.toLowerCase() === Status.MATCH.toLowerCase() &&
          item.shortlistStatus?.name.toLowerCase() !==
            EventType.DELISTED.toLowerCase()
      )
      .filter((match: Match) => {
        if (!search) return true;

        return match.candidate?.surname
          .toLowerCase()
          .includes(search.toLowerCase());
      });

    return await Promise.all(resultFiltered.map(remappingMatchForTableFields));
  }

  async function getCompanyApplications(search?: string): Match[] {
    const resultFiltered: Match[] = matchesStore.matches
      .filter(
        (item: Match) =>
          item.status.toLowerCase() === Status.OPPORTUNITY.toLowerCase()
      )
      .filter((match: Match) => {
        if (!search) return true;

        return match.candidate?.surname
          .toLowerCase()
          .includes(search.toLowerCase());
      });

    return await Promise.all(resultFiltered.map(remappingMatchForTableFields));
  }

  function getCompanyOpenSelections(search?: string): Match[] {
    return matchesStore.matches
      .filter((item: Match) =>
        VALID_STATUS_MATCH.openSelections.includes(item.status.toLowerCase())
      )
      .filter((match: Match) => {
        if (!search) return true;

        return match.candidate?.surname
          .toLowerCase()
          .includes(search.toLowerCase());
      });
  }

  async function getCompanyHistory(search?: string) {
    const resultFiltered: Match[] = matchesStore.matches
      .filter(
        (item: Match) =>
          VALID_STATUS_MATCH.history.includes(item.status.toLowerCase()) ||
          item.shortlistStatus?.name.toLowerCase() ===
            EventType.DELISTED.toLowerCase()
      )
      .filter((match: Match) => {
        if (!search) return true;

        return match.candidate?.surname
          .toLowerCase()
          .includes(search.toLowerCase());
      });

    return await Promise.all(resultFiltered.map(remappingMatchForTableFields));
  }

  function getMatchesResolved() {
    return remappingMatchForTableFields(matchesStore.matches);
  }

  const getPagination: ComputedRef<ClientPagination> | undefined = computed(
    () => {
      if (!matchesStore.pagination) return undefined;

      return {
        ...matchesStore.pagination,
        currentPage: matchesStore.pagination.currentPage,
        total: matchesStore.pagination.total,
        changePage: async (page: number) => {
          await matchesStore.changePage(page);
        }
      };
    }
  );

  return {
    getMatchInShortlist,
    getMatchInOpportunityStatus,
    getMatchOpenSelectionStatus,
    getMatchHistoryStatus,
    getCompanyMatches,
    getCompanyApplications,
    getCompanyOpenSelections,
    getCompanyHistory,
    getMatchesResolved,
    getMatchesForSortTable,
    getPagination,
    getMatchesForCompanies
  };
});
