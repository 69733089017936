import type { JobPosition } from '@emotioncod/cm-definitions/lib/Jobs';

interface State {
  jobPositions: JobPosition[];
  counts: {
    jobs: number;
    online: number;
    offline: number;
    candidatesSent: number;
    openSelections: number;
  };
  job: JobPosition | null;
  pagination: {
    currentPage: number;
    total: number;
    perPage: number;
  };
  cachedResult: number;
  isSearched: boolean;
}

const InitialState: State = {
  jobPositions: [],
  counts: {
    jobs: 0,
    online: 0,
    offline: 0,
    candidatesSent: 0,
    openSelections: 0
  },
  job: null,
  pagination: {
    currentPage: 0,
    total: 1,
    perPage: 30
  },
  cachedResult: 0,
  isSearched: false
};
export const useJobsPositionsState = defineStore('jobPositions.state', () => {
  const jobPositions = ref<State['jobPositions']>(InitialState.jobPositions);
  const pagination = ref<State['pagination']>(InitialState.pagination);
  const cachedResult = ref<State['cachedResult']>(InitialState.cachedResult);
  const job = ref<State['job']>(InitialState.job);
  const counts = ref<State['counts']>(InitialState.counts);
  const isSearched = ref<State['isSearched']>(InitialState.isSearched);

  return {
    jobPositions,
    pagination,
    cachedResult,
    job,
    counts,
    isSearched
  };
});
