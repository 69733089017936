import { useEventState } from '~/stores/events/state';

export const useEventGetters = defineStore('events.getters', () => {
  const { state } = useEventState();

  const getStatusMatch = () => {
    return state.statusMatch;
  };

  const getActionType = () => {
    return state.actionType;
  };

  return {
    getStatusMatch,
    getActionType
  };
});
