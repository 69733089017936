import { useEventState } from '~/stores/events/state';
import type { EventAction } from '~/stores/events/type';

export const useEventActions = defineStore('events.actions', () => {
  const { state } = useEventState();
  const dispatch = (event: EventAction): void => {
    state.payload = event.payload;
    state.actionType = event.eventType;
    state.statusMatch = event.statusMatch;
  };

  return {
    dispatch
  };
});
