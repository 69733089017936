import axios from 'axios';
import type { AxiosRequestConfig, AxiosRequestHeaders } from 'axios';
import ENV from '~/config/env';
import { execute } from '~/services/utils';

const axiosApiInstance = axios.create({
  baseURL: ENV.PROFILE_PAGE_URL + '/api',
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json',
    Accept: 'application/json',
    proxyHeaders: true,
    credentials: false
  }
} as AxiosRequestConfig);

axiosApiInstance.interceptors.request.use(
  (config) => {
    const authService = useNuxtApp().$authService;

    if (authService && authService?.isTokenValid()) {
      const authState = authService.getAuthState();
      const beToken = authState?.beToken || '';

      config.headers = {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + beToken
      } as AxiosRequestHeaders;
    }

    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

export const getUser = async (): Promise<
  | {
      profile: any; // todo
      uid: string;
      email: string;
    }
  | undefined
> => {
  return await execute<{
    profile: any; // todo
    uid: string;
    email: string;
  }>(axiosApiInstance.get(`/me?full=1`));
};
