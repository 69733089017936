import { defineStore } from 'pinia';
import { useEventActions } from './actions';
import { useEventState } from './state';
import { useEventGetters } from './getters';
import { extractStore } from '~/stores/extractStore';

export const useEvents = defineStore('events', () => {
  return {
    ...extractStore(useEventState()),
    ...extractStore(useEventGetters()),
    ...extractStore(useEventActions())
  };
});
