import {
  parseISO,
  isValid,
  format,
  Locale,
  isSameDay,
  differenceInMinutes,
  parse
} from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
// eslint-disable-next-line import/no-duplicates
import { it } from 'date-fns/locale';
import { EventType } from '@emotioncod/cm-definitions/lib/Matches/const';
import type { EventMatch } from '@emotioncod/cm-definitions/lib/Matches';
import type { CandidateLogEvents, LogEvents } from '~/types/bo-types';

const romaTimezone = 'Europe/Rome';

export function isTouchDevice() {
  return 'ontouchstart' in window || navigator.maxTouchPoints > 0;
}

/**
 * Filter an array by page
 * Use this function if server-side pagination is not available
 * @param array
 * @param total
 * @param currentPage
 * @param perPage
 */
export function filterArrayByPage<T>(
  array: T[],
  currentPage: number,
  perPage: number
): T[] {
  if (perPage === 0) return array;

  const startIndex = (currentPage - 1) * perPage;
  const endIndex = startIndex + perPage;

  // Check if the start index is within array bounds
  if (startIndex >= array.length) {
    return [];
  }

  // If the end index exceeds the total number, adjust it
  const filteredEndIndex = Math.min(endIndex, array.length);

  return array.slice(startIndex, filteredEndIndex);
}

/**
 * Deeply map an array of objects
 * This function is very waighed, use it only when needed
 * @param array
 */
export function deepMap<T>(array: T[]): T[] {
  return array.map((item) => {
    const mappedItem = { ...item };

    for (const key in mappedItem) {
      if (Object.prototype.hasOwnProperty.call(mappedItem, key)) {
        if (typeof mappedItem[key] === 'object') {
          mappedItem[key] = deepMap([mappedItem[key]])[0];
        }
      }
    }

    return mappedItem;
  });
}

/**
 * Debounce function
 * TODO: Type this function
 * @param func
 * @param wait
 */
export const debounce = (func: (a: any) => void, wait: number | undefined) => {
  let timeout: ReturnType<typeof setTimeout> | null;

  return function executedFunction(...args: any[]) {
    const later = () => {
      timeout = null;

      // @ts-ignore
      func(...args);
    };

    if (timeout) {
      clearTimeout(timeout);
    }

    timeout = setTimeout(later, wait);
  };
};

export function formatDateWithTime(date: string): string {
  const utcDate = new Date();
  const now = utcToZonedTime(utcDate, romaTimezone);
  let formattedDate = '';

  const parsedDate = parseISO(date);
  const isValidDate = isValid(parsedDate);

  if (isValidDate) {
    const isToday = isSameDay(parsedDate, now);

    if (isToday) {
      formattedDate = format(parsedDate, 'Pp', {
        locale: it as Locale
      });
    } else {
      formattedDate = format(parsedDate, 'Pp', {
        locale: it as Locale
      });
    }
  }

  return formattedDate;
}

/**
 * Format a date
 * @param date
 * @param isTimeStamp
 * @param isInputForm
 * @param isLogDate
 */
export function formatDate(
  date: string,
  isTimeStamp?: boolean,
  isInputForm = false,
  isLogDate = false
): string {
  const utcDate = new Date();
  const now = utcToZonedTime(utcDate, romaTimezone);
  let formattedDate = '';

  let parsedDate: Date;

  /**
   * @description If the date is a timestamp, it is converted to a number and then to a date
   **/
  if (isTimeStamp) {
    const timestamp = Number(date);
    parsedDate = isNaN(timestamp)
      ? parseISO(date)
      : utcToZonedTime(new Date(timestamp), romaTimezone);
  } else {
    parsedDate = parseISO(date);
  }

  const isValidDate = isValid(parsedDate);

  /**
   * @description Guard to check if the date is valid
   **/
  if (isValidDate) {
    const isToday = isSameDay(parsedDate, now);

    /**
     * @description If the date is today, the difference between the current date and the date to be formatted is calculated and show minutes or hours ago
     */
    if (isToday && !isInputForm) {
      formattedDate = formatDateWithTimeAndMinutes(parsedDate);
    } else if (isLogDate) {
      /**
       * @description If the date is a log date, the date is formatted with the month and the time
       * @see TAL-534
       */
      formattedDate = formatDateWithTimeAndMinutesAndMonth(parsedDate);
    } else {
      formattedDate = format(parsedDate, 'P', {
        locale: it as Locale
      });
    }
  }

  return formattedDate;
}

function formatDateWithTimeAndMinutes(date: Date): string {
  const utcDate = new Date();
  const now = utcToZonedTime(utcDate, romaTimezone);

  const diffInMinutes = differenceInMinutes(now, date);
  /**
   * 2 hours are subtracted because the server is in UTC and the client is in Europe/Rome
   */
  const hours = Math.floor(diffInMinutes / 60 - 2);
  const minutes = diffInMinutes % 60;

  if (hours > 0) {
    return `${hours} hours ${minutes} minutes ago`;
  } else if (minutes === 0) {
    return `now`;
  } else {
    return `${minutes} minutes ago`;
  }
}

// Nov 12, 18:01
export function formatDateWithTimeAndMinutesAndMonth(date: Date): string {
  return format(date, 'MMM dd, HH:mm');
}

export function shuffleArray(array: any[]) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
}

/**
 * @todo unify the CandidateEvents interface and the EventMatch interface
 */
export function sortEventsDates(
  events: CandidateLogEvents,
  orderBy: 'asc' | 'desc',
  filerBy: 'all' | 'candidateNotes' | 'matchNotes' | 'matchEvents'
): LogEvents[] {
  if (!events) return [];
  let result = [];

  const compareDates = (
    event1: { date: string | undefined },
    event2: { date: string | undefined }
  ): number => {
    if (!event1.date || !event2.date) return 0;

    const parsedDate1 = new Date(event1.date).getTime();
    const parsedDate2 = new Date(event2.date).getTime();

    if (orderBy === 'asc') {
      return parsedDate1 - parsedDate2;
    } else {
      return parsedDate2 - parsedDate1;
    }
  };

  if (filerBy !== 'all') {
    result = events[filerBy].sort(compareDates);
  } else {
    result = [
      ...events?.matchEvents,
      ...events?.matchNotes,
      ...events?.candidateNotes
    ].sort(compareDates);
  }

  if (orderBy === 'desc') {
    result.reverse();
  }

  return result.map((event) => ({
    authorName: event.authorName || '',
    content: event.content || '',
    type: event.type || '',
    source: event.source || '',
    date: event.date || '',
    deleted_at: event.deleted_at || '',
    jobsName: event.jobsName || '',
    jobsId: event.jobsId || '',
    companyName: event.companyName || '',
    companyId: event.companyId || '',
    by: event.by || ''
  }));
}

export function groupEventsByDate(
  events: Array<LogEvents>,
  orderBy: 'asc' | 'desc'
): {
  [date: string]: Array<LogEvents>;
} {
  if (!events) return {};

  const sortedEvents = [...events].sort((a, b) => {
    const dateA = new Date(a.date);
    const dateB = new Date(b.date);

    return dateA.getTime() - dateB.getTime();
  });

  if (orderBy === 'asc') {
    sortedEvents.reverse();
  }

  return sortedEvents.reduce(
    (
      acc: {
        [date: string]: Array<LogEvents>;
      },
      event: LogEvents
    ) => {
      const now = new Date();
      const parsedDate = parseISO(event.date);
      const isToday = isSameDay(parsedDate, now);
      let date;

      if (isToday) {
        date = 'Today';
      } else {
        const parsedDate = parseISO(event.date);

        // @ts-ignore
        if (isValid(parsedDate)) {
          date = format(parsedDate, 'MMM dd, yyyy');
        } else {
          date = 'Invalid Date';
        }
      }

      if (!acc[date]) {
        acc[date] = [];
      }

      acc[date].push(event);
      return acc;
    },
    {}
  );
}

export function parseDataStringToISO(dataInput: string): string {
  const parsedDate = parse(dataInput, 'dd/MM/yyyy', new Date());

  return format(parsedDate, 'yyyy-MM-dd HH:mm:ss');
}

export const handleOpenActionsMenu = (
  event: CustomEvent<boolean> & { target: HTMLElement },
  setPadding: (value: number) => void
) => {
  if (!event.target) return;

  setTimeout(() => {
    const dropDown = event.target.getElementsByClassName(
      'cm-dropdown-container'
    );

    if (dropDown) {
      setPadding(dropDown[0]?.getBoundingClientRect().height);
    }
  }, 300);
};

export function isNoteEmpty(events: Partial<EventMatch>[]): boolean {
  if (!events || events.length === 0) return true;

  const someNoteAdded = events.some((event) => {
    return (
      event.type?.toLowerCase() === EventType.NOTE_ADDED.toLowerCase() ||
      event.type?.toLowerCase() === EventType.NOTE.toLowerCase()
    );
  });

  return !someNoteAdded;
}

export const concatStringsErrors = (
  errors: { $message: string; $response: { $message?: string } }[]
) => {
  return errors.map((error) => error.$message).join('\n');
};

export function addVersionNumber(version: string, versionToAdd: string) {
  const versionNumbers = version.split('.').map(Number);
  const versionToAddNumbers = versionToAdd.split('.').map(Number);

  for (let i = 0; i < versionNumbers.length; i++) {
    versionNumbers[i] += versionToAddNumbers[i];
  }

  return versionNumbers.join('.');
}

export function toCapitalize(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function openInNewTab(url: string) {
  const win = window.open(url, '_blank');
  win?.focus();
}

export function parseImage(file: string): string {
  /**
   * @description If the file is a google drive string, change it to a google drive link
   * https://drive.google.com/uc?id=14hd1wnipNcgBHyQz8nxM9dfKBlGl9LzA
   * Change https://drive.google.com/uc?export=view&id={img-ID} to https://lh3.google.com/u/0/d/{img-ID}
   * https://lh3.google.com/u/0/d/14hd1wnipNcgBHyQz8nxM9dfKBlGl9LzA
   * https://lh3.googleusercontent.com/fife/AGXqzDm58sZGgyTA8lnjE5Eo8zvnyZM5afU5flmCqhzTObqBcC17GHr2eVTwVOvLqzvmuOruPYKt0uQULjaHviJ-6qO7JJYOY7ryqrVRknGY3Sc9n8il2vj09_ykKz7BY1Lt1xiY9vq335hziGkrPcch_dJo_U7iO8ntSTTsmoubxqHjaFJplB9jK5bR-Dt93Sgy19SmAPxA1i2VZzTPnn_nyFmjpQ1cHlajozgINcWopFDNxAQKi4CvWDa1bfCvdIwaAjM-GbwSLAaUBa3SpjzApIBwsFL40Z1_1KKPd-izyO6tRjqIWhiuSBPwIeTk6z08yb8xe7qgnNJp2Rc88JIaEXPeWqpMkj69bEJShSRsi8BEGO2RvXBeIQiyOEphqjtfrmoDa0BYW2Zf2bQFWDqP2Oh9vXEbTXuygG2k8KbAY00gX_xYlMZzv_I_YbX4RjwMppxKaYmQYQlb9rs1n6u4fKoLbaRTozNh76UAldZuP3a5pCCcWHWi5ZH7o81dU3XGFKcHzYaUzNOeK_MxXOoSpr7kZMJU-ecDwEzaFLHEqRY2ndJOdh0IzESA7sYwPZep_SBYiBqlI-pakATBE9WbkPg_heKG4FSuTqYMk1zzvsPBJzVUvXNk6VjdP-QgVuyWiqAYpAxHFVCW1J-aXRr-3aSfqicxGu7k_Xsmh-Bz1sBGNGW0lTJV4BT_YkOlEDmdT7UoUnq2InaV1waV35xlZG8ESEP0P4oWrmlDX234q5N8bYIRW2tZQy0zmUXyNXWOzlakFuYYLetMifuxSqmwBEarY3Fvg-wU_HX2OJwu0jPpl6Zd7K2wZka7sSLsYZfgs25bViW8jDV8OkpFgFXxIuBft0w0oIHsVsDsdajaIXtGmk5F2DhwDh6GGa3b5LSeheUAHMRkjP-CvX7YokNBsLo-UDJQmL7MJpbZ2uZDFuy4O-oqjao_uKFceA0T8RHK3moZ_pIdBXTgKBCNru-LIG0Zi87dMBXoHhjP04iOHuA9gk3iMu_BMOHihUxqLGCj72oxL5BeIDCmFKHMkUSJLNZjhUnyg45OiHPOms8Q4lEOzebwYnFg5nJsGU4mOkyo49jqSSBszbSpT9wwAH4_mYqR6zajnOK4H8NHP781JDZFky5lsC_pfklZGSfUhRWYDNQF-kAZp6XPtoayoe44YaHGdHUQI-vFr9uMrRJTzsRJdiWE_bMdr1iaglQFmrrjrTsAk6JHy_-gPBUTJMjig9Nte8p1nTQVZfNupQ6cEHIqC-Oj8e6_oz3B9zSHsPACHYgOor-wlx19LwWG6hJmlfwfY8oYsSZN_h4YR5vgSeK1zpwVAwhUmo1hQcF7VdwNm1JrAckzN0VG9I91YFFcC-tltrcp9pXHeAtsy7Xkw8nExQisQdftoafHlaPKvNw8FseyO_IzqgzUhYY3N6El-L8aTvrmKHCXnQM1FnSZDBIfcwiG3qdURbWRfz_bcfHmFAy8ELmtWU_5tY-d2z_YuEysk3mHP30oIvR0BHPrqlGnLMFF5-PHb-pcL80OuuMGrZfFWuNaE5-Xm-krE7tCmjqAsPo4IAKnAC7qXCw-YxVYlSYfwg
   * https://lh3.googleusercontent.com/drive-viewer/AEYmBYSOsAREHvW9RsqZ4iWNiL5QYLwh0dx066NIBlsUh6xv1_MGL-Qcc8xrV7DO-FSZNcAZm8R4j5bAWNZOFMhoI7UOqR6QIA=w3360-h1858
   *
   * Method 2
   * https://www.googleapis.com/drive/v3/files/FILE_ID?alt=media&key=APIKEY
   */
  if (file.match(/drive.google.com/)) {
    const config = useRuntimeConfig();
    const api = config.public.googleCloudDriveApi;

    const id =
      file.match(/id=(.*?)(&|$)/)?.[1] ||
      file.match(/\/d\/([^\/]+)\/view/)?.[1];

    if (!id) return file;

    return `https://www.googleapis.com/drive/v3/files/${id}?alt=media&key=${api}`;
  }

  return file;
}

export function capitalizeStr(string: string): string {
  if (!string) return '';

  return string.charAt(0)?.toUpperCase() + string.slice(1);
}
