import type {
  Company,
  CompanyItems,
  CompanyWithCounts,
  KeyValuesIndustry,
  CompanyList,
  CompanyType,
  CompanyManager
} from '@emotioncod/cm-definitions/lib/Companies';
import type { ClientPagination } from '~/types/bo-types';

export interface CompaniesState {
  companiesIdArray: Array<CompanyList>;
  companies: Array<CompanyList>;
  company: Company | null;
  companyMatch: Company | null;
  companyUsers: Array<CompanyManager>;
  user: Partial<CompanyManager>;
  bccList: string;
  types: Array<CompanyType>;
  keyValuesIndustry: KeyValuesIndustry | null;
  companyItems: CompanyItems | null;
  cachedResult: number;
  isSearched: boolean;
  counts: CompanyWithCounts['counts'];
  pagination: ClientPagination;
}

export const InitialState: CompaniesState = {
  companiesIdArray: [],
  companies: [],
  company: null,
  companyMatch: null,
  companyUsers: [],
  user: {
    email: '',
    password: '',
    name: '',
    role: 'Owner',
    surname: '',
    telephone: ''
  },
  bccList: '',
  types: [],
  keyValuesIndustry: null,
  companyItems: null,
  cachedResult: 0,
  isSearched: false,
  counts: {
    active: 0,
    italy: {
      pro: 0,
      core: 0
    },
    spain: {
      pro: 0,
      core: 0
    },
    activeTalent: 0,
    activeTalentPro: 0
  },
  pagination: {
    currentPage: 0,
    total: 1,
    perPage: 30
  }
};

export const useCompaniesState = defineStore('companies.state', () => {
  const counts = ref(InitialState.counts);
  const companies = ref(InitialState.companies);
  const companiesIdsArray = ref(InitialState.companiesIdArray);
  const company = ref(InitialState.company);
  const keyValuesIndustry = ref(InitialState.keyValuesIndustry);
  const companyItems = ref(InitialState.companyItems);
  const cachedResult = ref(InitialState.cachedResult);
  const isSearched = ref(InitialState.isSearched);
  const pagination = ref(InitialState.pagination);
  const types = ref(InitialState.types);
  const companyUsers = ref(InitialState.companyUsers);
  const user = ref(InitialState.user);
  const companyMatch = ref(InitialState.companyMatch);
  const bccList = ref(InitialState.bccList);

  return {
    companies,
    keyValuesIndustry,
    companyItems,
    company,
    cachedResult,
    isSearched,
    counts,
    pagination,
    companiesIdsArray,
    types,
    companyUsers,
    user,
    companyMatch,
    bccList,
  };
});
