import { defineStore } from 'pinia';
import { useMatchesState } from '~/stores/matches/state';
import { useMatchesGetters } from '~/stores/matches/getters';
import { useMatchesActions } from '~/stores/matches/actions';
import { extractStore } from '~/stores/extractStore';

export const useMatches = defineStore('matches', () => {
  return {
    ...extractStore(useMatchesState()),
    ...extractStore(useMatchesGetters()),
    ...extractStore(useMatchesActions())
  };
});
