import {
  ActionType,
  EventType,
  Status
} from '@emotioncod/cm-definitions/lib/Matches/const';
import type { StateEvents } from '~/stores/events/type';
import MatchService from '~/services/admin/match';
import { statusMatchesMap } from '~/stores/events/map';

const InitialSettings: StateEvents = {
  statusMatch: Status.INITIAL,
  actionType: ActionType.INITIAL,
  payload: {
    matchId: '',
    userId: '',
    matches: []
  }
};

export const useEventState = defineStore('events.state', () => {
  const state = ref<StateEvents>(InitialSettings);

  const settings = useSettings();
  const { getMatchesFromCandidates } = useMatches();

  /**
   * Side Effects for Actions type
   */
  watch(
    () => state.value.actionType,
    async () => {
      if (
        !state.value.actionType ||
        state.value.actionType.toLowerCase() ===
          ActionType.INITIAL.toLowerCase()
      )
        return;

      // settings.showSpinner(true);

      switch (state.value.actionType) {
        case ActionType.OPEN_NOTES:
          settings.settings.showSidePanel = true;
          /**
           * Reset status
           */
          state.value.actionType = ActionType.INITIAL;
          // settings.showSpinner(false);
          return;
        case ActionType.CLOSE_NOTES:
          settings.settings.showSidePanel = false;
          /**
           * Reset status
           */
          state.value.actionType = ActionType.INITIAL;
          // settings.showSpinner(false);
          return;
        case ActionType.FAILED_POSITION_UNPUBLISHED:
          if (
            state.value.statusMatch?.toLowerCase() !==
            Status.MATCH.toLowerCase()
          ) {
            statusMatchesMap.set(ActionType.FAILED_POSITION_UNPUBLISHED, {
              event: EventType.POSITION_UNPUBLISHED,
              status: Status.FAILED_SELECTION
            });
          }
          break;
        case ActionType.RESTORE_TO_SHORTLIST:
          /**
           * Update ShortList Status to LISTED after restored match
           * (Status Match = MATCH)
           */
          await MatchService.postEventMatch(state.value.payload.matchId, {
            type: EventType.RESTORED_TO_SHORTLIST,
            date: new Date().toISOString(),
            content: '',
            source: 'Talent partner'
          });
          break;
        case ActionType.PUBLISHED_IN_SHORTLIST:
          /**
           * When clicked on publish shortlist button we execute this
           */
          // eslint-disable-next-line no-case-declarations
          const shortListPubResp = await MatchService.publishShortListArray(
            state.value.payload.userId,
            state.value.payload.matches || []
          );

          if (!shortListPubResp) {
            return;
          }
          break;
        default:
          break;
      }

      const matchStatus = statusMatchesMap.get(state.value.actionType);

      if (matchStatus) {
        if (matchStatus.status) {
          /**
           * Update Match Status
           */
          const matchResponse = await MatchService.updateMatchStatusById(
            state.value.payload.matchId,
            matchStatus.status
          );

          if (matchStatus.event && matchResponse && matchResponse.id) {
            /**
             * @description Dispatch events after update match status
             */
            await MatchService.postEventMatch(state.value.payload.matchId, {
              type: matchStatus.event,
              date: new Date().toISOString(),
              content: '',
              source: 'Talent partner'
            });
          }
        }

        /**
         * @description If status is not defined, we dispatch only event
         */
        if (matchStatus.event && !matchStatus.status) {
          /**
           * @deprecated This is deprecated, we use the new endpoint to publish shortlist & matches events changes by server
           * @description Dispatch events after for each match in the array of matches
           * This role is when we 'publish the shortlist' and we have a list of matches
           */
          if (state.value.payload.matches) {
            for (const match of state.value.payload.matches) {
              if (match.shortlistStatus?.id === 2) {
                await MatchService.postEventMatch(match.id, {
                  type: matchStatus.event,
                  date: new Date().toISOString(),
                  content: '',
                  source: 'Talent partner'
                });
              }
            }
          } else {
            await MatchService.postEventMatch(state.value.payload.matchId, {
              type: matchStatus.event,
              date: new Date().toISOString(),
              content: '',
              source: 'Talent partner'
            });
          }
        }

        /**
         * Update the inernal state with the new status match
         */
        await getMatchesFromCandidates(state.value.payload.userId);
      }

      /**
       * Reset status
       */
      state.value.actionType = ActionType.INITIAL;

      // settings.showSpinner(false);
    }
  );

  return { state };
});
