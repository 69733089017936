import { AxiosPromise } from 'axios';
import type { BaseResponseBody } from '@emotioncod/cm-definitions';
import { ActionType } from '@emotioncod/cm-definitions/lib/Matches/const';
import { HTTPHandleError } from '~/errors/httpHandleError';

export async function execute<T>(
  fn: AxiosPromise<BaseResponseBody<T>>
): Promise<T | undefined> {

  const settingsStore = useSettings();
  const eventsStore = useEvents();

  try {
    const response = await fn;
    return response.data.data;
  } catch (error: unknown) {
    /**
     * Reset side effects
     */
    settingsStore.showSpinner(false);
    eventsStore.state.actionType = ActionType.INITIAL;

    HTTPHandleError(error, settingsStore.showSnackabr);


    return undefined;
  }
}
