import {
  EventType,
  Status
} from '@emotioncod/cm-definitions/lib/Matches/const';

export const VALID_STATUS_MATCH = {
  openSelections: [
    Status.SELECTION.toLowerCase(),
    Status.INTERVIEW.toLowerCase(),
    Status.OFFER.toLowerCase()
  ],
  history: [
    Status.FAILED_MATCH.toLowerCase(),
    Status.FAILED_SELECTION.toLowerCase(),
    Status.ARCHIEVED.toLowerCase(),
    Status.HIRED.toLowerCase()
  ]
};

export const EVENT_BY = {
  developer: [
    EventType.PROPOSAL_ACCEPTED.toLowerCase(),
    EventType.PROPOSAL_REJECTED.toLowerCase(),
    EventType.OFFER_ACCEPTED.toLowerCase(),
    EventType.OFFER_REJECTED.toLowerCase(),
    EventType.REJECTED_BY_DEV.toLowerCase()
  ],
  company: [
    EventType.REJECTED_BY_COMPANY.toLowerCase(),
    EventType.POSITION_UNPUBLISHED.toLowerCase()
  ]
};
