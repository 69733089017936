export class CMError extends Error {
  constructor(
    message: string,
    public family: CMError.ERROR_FAMILY = CMError.ERROR_FAMILY.Generic,
    public type: CMError.ERROR_TYPE = CMError.ERROR_TYPE.Fatal,
    public sourceError?: Error,
    public uiError?: {
      title: string;
      description: string;
      analyticsCode: string;
    }
  ) {
    super(message);
    // https://github.com/Microsoft/TypeScript/wiki/Breaking-Changes#extending-built-ins-like-error-array-and-map-may-no-longer-work
    Object.setPrototypeOf(this, CMError.prototype);
  }
}

export namespace CMError {
  export enum ERROR_TYPE {
    Recoverable,
    Fatal,
    Warning,
    Ignorable,
    Retriable
  }
  export enum ERROR_FAMILY {
    Internal,
    Generic,
    Unknown,
    LoggedIn,
    InvalidResponse,
    EmptyData
  }
}
