import type { Match } from '@emotioncod/cm-definitions/lib/Matches';
import {
  EventType,
  Status
} from '@emotioncod/cm-definitions/lib/Matches/const';
import { Languages, Source } from '@emotioncod/cm-definitions/lib/Jobs/const';

interface State {
  pagination: {
    currentPage: number;
    total: number;
    perPage: number;
  };
  matches: Match[];
  match: Match;
  cachedResult: number;
  /**
   * This is a client side search.
   * It's used to get all candidates list from cache and filter them from UI.
   * TODO: remove this when we have a server side search.
   */
  isSearched: boolean;
  /**
   * This is used to cache some data in the store.
   */
  companyId: string;
}

const InitialState: State = {
    pagination: {
        currentPage: 0,
        total: 1,
        perPage: 30
    },
    matches: [],
    match: {
        id: '',
        matchId: '',
        jobPosition: null,
        candidate: null,
        date: new Date ().toISOString (),
        source: 'Talent partner',
        opportSwdSource: Source.TALENT,
        matchmaker: {
            email: '',
            name: '',
            photo: '',
            surname: '',
            uid: ''
        },
        affinity: 0,
        status: Status.MATCH,
        events: [],
        shortlistStatus: {
            id: 1,
            name: EventType.LISTED
        },
        country: Languages.ITALIAN
    },
    cachedResult: 0,
    isSearched: false,
    companyId: ''
};

export const useMatchesState = defineStore('matches.state', () => {
  const matches = ref<State['matches']>(InitialState.matches);
  const match = ref<State['match']>(InitialState.match);
  const pagination = reactive<State['pagination']>(InitialState.pagination);
  const cachedResult = ref<State['cachedResult']>(InitialState.cachedResult);
  const isSearched = ref<State['isSearched']>(InitialState.isSearched);
  const companyId = ref<State['companyId']>(InitialState.companyId);

  return {
    matches,
    pagination,
    cachedResult,
    isSearched,
    match,
    companyId
  };
});
