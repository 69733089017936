import { storeToRefs } from 'pinia';
import type { EventMatch, Match } from '@emotioncod/cm-definitions/lib/Matches';
import type { ResponseBody } from '@emotioncod/cm-definitions/lib/ServiceLayer/Admin/Matches/Candidate/GET';
import { useMatchesState } from './state';
import MatchesServices from '~/services/admin/matches/index';
import { useCompanies } from '~/stores/companies';
import MatchService from '~/services/admin/match';

export const useMatchesActions = defineStore('matches.actions', () => {
  const { settings } = useSettings();
  const { getCompaniesByIdsArray } = useCompanies();

  const { match, matches, pagination, cachedResult, isSearched, companyId } =
    storeToRefs(useMatchesState());

  const route = useRoute();
  const { push } = useRouter();

  const changePage = async (
    filterBy: 'all' | 'matches' | 'applications' | 'openselections' | 'history',
    withPage: number,
    search?: string
  ): Promise<void> => {
    await getMatches(filterBy, 30, withPage, search);

    pagination.value.currentPage = withPage;

    const page = (withPage + 1).toString();

    const query = {
      ...route.query,
      ...(filterBy && { filter: filterBy }),
      page,
      ...(search && { search })
    };

    push({
      path: route.path,
      query
    });
  };

  const updateMatches = (m: ResponseBody['data']): void => {
    if (!m) return;

    matches.value = [...m.data].filter((item) => !item?.deleted_at);
    pagination.value.total = m.last_page - 1;
    pagination.value.perPage = Number(m.per_page);
  };

  const updateCacheResult = (timestamp: number): void => {
    cachedResult.value = timestamp;
  };

  async function postEventMatch(
    matchId: string,
    event: Partial<EventMatch>
  ): Promise<Match | undefined> {
    if (!settings.loggedIn) {
      return undefined;
    }

    const response = await MatchService.postEventMatch(matchId, event);

    if (response) {
      const index = matches.value.findIndex((match) => match.id === matchId);

      matches.value = [
        ...matches.value.slice(0, index),
        response,
        ...matches.value.slice(index + 1)
      ];

      return response;
    }
  }

  async function getMatchesFromCandidates(
    id: string,
    searchValue?: string,
    perPage = 1000
  ) {
    if (!settings.loggedIn) {
      return undefined;
    }

    const response = await MatchesServices.getMatchesByCandidateId(id, perPage);

    if (response) {
      isSearched.value = false;

      updateMatches(response);
      updateCacheResult(Date.now());
      return response.data;
    }
  }

  watch(matches, async () => {
    const companiesIds = matches.value
      .map((match) => {
        if (!match.jobPosition) return '';

        return match.jobPosition.company_id;
      })
      .filter((id) => id);

    if (!companiesIds.length) return;

    await getCompaniesByIdsArray(companiesIds);
  });

  const addNewMatch = async (payload: Match): Promise<Match | undefined> => {
    if (!settings.loggedIn) {
      return undefined;
    }

    const response = await MatchService.addNewMatch(payload);

    if (response) {
      match.value = response;
      return response;
    }
  };

  const updateMatch = async (
    matchId: string,
    payload: Match
  ): Promise<Match | undefined> => {
    if (!settings.loggedIn) {
      return undefined;
    }

    const response = await MatchService.updateMatch(matchId, payload);

    if (response) {
      match.value = response;
      return response;
    }
  };

  const getMatchesByCompanyId = async (
    id: string
  ): Promise<Match[] | undefined> => {
    if (!settings.loggedIn) {
      return undefined;
    }

    /**
     * @description If the companyId is the same as the last one, we return the matches
     * @deprecated
     * @see https://codemotionplatform.atlassian.net/browse/TAL-528?atlOrigin=eyJpIjoiODk5ZTlhMmZhZTIyNGRlZDhlNDVmOWM5YzQ2M2QzN2QiLCJwIjoiaiJ9
     * @todo Remove this when the bug is fixed
     * Ottimizzazione per evitare di fare chiamate inutili
     */
    if (companyId.value === id) {
      // return matches.value;
    }

    const response = await MatchesServices.getMatchesByCompanyId(id);

    if (response) {
      companyId.value = id;
      matches.value = response.data;
      return response.data;
    }
  };

  async function getMatchesByJobId(jobId: string) {
    if (!settings.loggedIn) {
      return undefined;
    }

    const response = await MatchesServices.getMatchesByJobId(jobId);

    if (response) {
      matches.value = response.data;
      return response.data;
    }
  }

  async function getMatches(
    filterBy:
      | 'all'
      | 'matches'
      | 'applications'
      | 'openselections'
      | 'history' = 'all',
    perPage = 30,
    page = 0,
    searchValue?: string
  ) {
    if (!settings.loggedIn) {
      return undefined;
    }

    /**
     * @description CmPagination has a index array from 0, so we call "page + 1"
     */
    function getCurrentPage(): number {
      if (page === 0) return 1;

      if (page) {
        return page + 1;
      }

      if (pagination.value.currentPage) {
        return pagination.value.currentPage + 1;
      }

      return 1;
    }

    const cPage = getCurrentPage();

    const response = await MatchesServices.getMatches(
      filterBy,
      perPage,
      cPage,
      searchValue
    );

    if (response) {
      isSearched.value = false;

      updateMatches(response);
      updateCacheResult(Date.now());
      return response.data;
    }
  }

  async function getMatchById(id: string) {
    if (!settings.loggedIn) {
      return undefined;
    }

    const response = await MatchesServices.getMatchById(id);

    if (response) {
      match.value = response;
      return response;
    }
  }

  return {
    getMatchesFromCandidates,
    postEventMatch,
    addNewMatch,
    updateMatch,
    getMatchesByCompanyId,
    getMatchesByJobId,
    getMatches,
    changePage,
    updateMatches,
    getMatchById
  };
});
