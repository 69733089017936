import type {
  ActionEventType,
  EventMatchType,
  MatchStatus
} from '@emotioncod/cm-definitions/lib/Matches';
import {
  EventType,
  ActionType,
  Status
} from '@emotioncod/cm-definitions/lib/Matches/const';
/**
 * @see https://www.notion.so/WIP-Events-and-data-142c785686864ee58114b22a1e7663e2
 */
export const statusMatchesMap = new Map<
  ActionEventType,
  { event: EventMatchType | null; status: MatchStatus | null }
>([
  [
    ActionType.PROPOSAL_ACCEPTED,
    { event: EventType.PROPOSAL_ACCEPTED, status: Status.OPPORTUNITY }
  ],
  [
    ActionType.PROPOSAL_REJECTED,
    {
      event: EventType.PROPOSAL_REJECTED,
      status: Status.FAILED_MATCH
    }
  ],
  [
    ActionType.FAILED_POSITION_UNPUBLISHED,
    {
      event: EventType.POSITION_UNPUBLISHED,
      status: Status.FAILED_MATCH
    }
  ],
  [
    ActionType.PUBLISH_CLIENT_DASHBOARD,
    {
      event: EventType.PUBLISHED_TO_COMPANY_DASHBOARD,
      status: Status.SELECTION
    }
  ],
  [ActionType.ARCHIVE, { event: null, status: Status.ARCHIEVED }],
  [
    ActionType.OFFER_ACCEPTED,
    { event: EventType.OFFER_ACCEPTED, status: Status.HIRED }
  ],
  [
    ActionType.OFFER_REJECTED,
    {
      event: EventType.OFFER_REJECTED,
      status: Status.FAILED_SELECTION
    }
  ],
  [
    ActionType.MOVE_SELECTION_TO_OFFER,
    { event: EventType.MOVED_TO_OFFER, status: Status.OFFER }
  ],
  [
    ActionType.MOVE_SELECTION_TO_INTERVIEW,
    { event: EventType.MOVED_TO_INTERVIEW, status: Status.INTERVIEW }
  ],
  [
    ActionType.REJECTED_BY_DEV,
    {
      event: EventType.REJECTED_BY_DEV,
      status: Status.FAILED_SELECTION
    }
  ],
  [
    ActionType.REJECTED_BY_COMPANY,
    {
      event: EventType.REJECTED_BY_COMPANY,
      status: Status.FAILED_SELECTION
    }
  ],
  [
    ActionType.RESTORE_TO_SHORTLIST,
    { event: EventType.LISTED, status: Status.MATCH }
  ],
  [
    ActionType.MATCH_CREATED,
    { event: EventType.MATCH_CREATED, status: Status.MATCH }
  ],
  [ActionType.PUBLISHED_IN_SHORTLIST, { event: null, status: null }],
  [
    ActionType.LISTED,
    { event: EventType.LISTED, status: null } // purtroppo se passo LISTED_FOR_SHORTLIST l'evento risulta coerente ma lo shortlistStatus non viene popolato, quindi passo LISTED
  ],
  [
    ActionType.READY_TO_PUBLISH,
    { event: EventType.READY_TO_PUBLISH, status: null } // purtroppo se passo READY_TO_PUBLISH_IN_SHORTLIST l'evento risulta coerente ma lo shortlistStatus non viene popolato, quindi passo READY_TO_PUBLISH
  ],
  [ActionType.DELISTED, { event: EventType.DELISTED, status: null }]
]);
