import { ComputedRef } from 'vue';
import type { StatCardData } from '@emotioncod/cm-design-system/dist/types/types';
import type { JobPosition } from '@emotioncod/cm-definitions/lib/Jobs';
import type { Match } from '@emotioncod/cm-definitions/lib/Matches';
import { PublishStatus } from '@emotioncod/cm-definitions/lib/Jobs/const';
import JobsServices from '~/services/admin/jobs';
import type { ClientPagination } from '~/types/bo-types';
import {
  resolveJobPositionsStats,
  resolveJobPositionStatistics,
  resolveJobPositionStats,
  resolveLocationCountry,
  resolvePositionType
} from '~/resolvers/jobPositions';
import { formatDate } from '~/utils';

export const useJobsPositionsGetters = defineStore(
  'jobPositions.getters',
  () => {
    const jobPositionsStore = useJobsPositions();
    const companiesStore = useCompanies();
    const matchesStore = useMatches();

    const getJobsPositionById = async (jobId: string) => {
      const response = await JobsServices.getJobPositionsById(jobId);

      if (response) {
        return {
          ...response,
          list: {
            ...response.list,
            data: response.list.data.filter(
              (item) =>
                item.publish_status.toLowerCase() !==
                PublishStatus.ARCHIVED.toLowerCase()
            )
          }
        };
      }
    };

    const getterJobsPositions = (
      search?: string
    ): (JobPosition & { positionType: string })[] => {
      return (
        jobPositionsStore.jobPositions
          // @ts-ignore
          .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
          .filter(
            (item) =>
              item.publish_status.toLowerCase() !==
              PublishStatus.ARCHIVED.toLowerCase()
          )
          .map((job) => {
            return {
              ...job,
              created_at: job?.created_at ? formatDate(job.created_at) : '-',
              positionType: job?.source ? resolvePositionType(job.source) : '-'
            };
          })
          .filter((item) => {
            if (search) {
              return item.name.toLowerCase().includes(search.toLowerCase());
            }
            return item;
          })
      );
    };

    async function resolveItemForSortTable(item: JobPosition): Promise<
      | JobPosition & {
          candidateSent: number;
          country: string;
        }
    > {
      const jobs = await jobPositionsStore.getCandidateJobsStats(item.id);

      return {
        ...item,
        candidateSent: jobs?.candidatesSent || 0,
        country: resolveLocationCountry(item.company_country, item.location)
      };
    }

    async function getJobPositionsForSortTable(): Promise<
      (JobPosition & {
        candidateSent: number;
        country: string;
      })[]
    > {
      const filteredJobs: JobPosition[] = jobPositionsStore.jobPositions.filter(
        (item) =>
          item.publish_status.toLowerCase() !==
          PublishStatus.ARCHIVED.toLowerCase()
      );

      return await Promise.all(filteredJobs.map(resolveItemForSortTable));
    }

    const getPagination: ComputedRef<ClientPagination | undefined> = computed(
      () => {
        if (!jobPositionsStore.pagination) return undefined;

        return {
          ...jobPositionsStore.pagination,
          currentPage: jobPositionsStore.pagination.currentPage,
          total: jobPositionsStore.isSearched
            ? 0
            : jobPositionsStore.pagination.total,
          changePage: async (page: number) => {
            await jobPositionsStore.changePage(page);
          }
        };
      }
    );

    const getJobPositionsStats = (
      t: (key: string, ...args: any[]) => string
    ): StatCardData[] => {
      if (!jobPositionsStore.counts) return [];

      return resolveJobPositionsStats(jobPositionsStore.counts, t);
    };

    async function getJobPositionDetails(
      t: (key: string, ...args: any[]) => string
    ): Promise<
      | (JobPosition & {
          companyLogo: string;
          stats: StatCardData[];
          statistics: StatCardData[];
          matches: Match[];
          companyName: string;
        })
      | undefined
    > {
      if (!jobPositionsStore.job) return;

      const jobStats = await jobPositionsStore.getCandidateJobsStats(
        jobPositionsStore.job.id
      );

      const companyResponse = await companiesStore.getCompaniesByIds(
        jobPositionsStore.job?.company?.id || jobPositionsStore.job?.company_id
      );

      const matches = await matchesStore.getMatchesByJobId(
        jobPositionsStore.job.id
      );

      const stats = resolveJobPositionStats(jobPositionsStore.job, t);
      const statistics = resolveJobPositionStatistics(jobStats, t);

      return {
        ...jobPositionsStore.job,
        openSelections: jobStats ? jobStats.openSelections : 0,
        companyLogo: companyResponse
          ? companyResponse.company?.logo_url ||
            companyResponse.company?.page?.logo_url
          : '',
        stats,
        statistics,
        matches: matches && matches?.length > 0 ? matches : [],
        companyName: companyResponse ? companyResponse.company.name : ''
      };
    }

    return {
      getJobsPositionById,
      getJobPositionsForSortTable,
      getPagination,
      getJobPositionsStats,
      getterJobsPositions,
      getJobPositionDetails
    };
  }
);
