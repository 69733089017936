import { defineStore } from 'pinia';
import { useJobsPositionsActions } from './actions';
import { useJobsPositionsState } from './state';
import { useJobsPositionsGetters } from './getters';
import { extractStore } from '~/stores/extractStore';

export const useJobsPositions = defineStore('jobsPositions', () => {
  return {
    ...extractStore(useJobsPositionsState()),
    ...extractStore(useJobsPositionsGetters()),
    ...extractStore(useJobsPositionsActions())
  };
});
