import { defineStore, StoreDefinition } from 'pinia';
import { useCompaniesActions } from './actions';
import { useCompaniesState } from './state';
import { useCompaniesGetters } from './getters';
import { extractStore } from '~/stores/extractStore';

export const useCompanies: StoreDefinition = defineStore('companies', () => {
  return {
    ...extractStore(useCompaniesState()),
    ...extractStore(useCompaniesGetters()),
    ...extractStore(useCompaniesActions())
  };
});
